<template>
	<div class="incidentsalertsFormHeader puiformheader">
		<v-row dense>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.distressid')" :value="getDistressidValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.typecode')" :value="getTypedescValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.date')" :value="getFormattedUploaddate"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.statusdesc')" :value="getStatusdescValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'incidentsalerts-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'distressalerts'
		};
	},
	computed: {
		getDistressidValue() {
			return this.model && this.model.distressid ? this.model.distressid : '-';
		},
		getTypedescValue() {
			return this.model && this.model.typedesc ? this.model.typedesc : '-';
		},
		getDateValue() {
			return this.model && this.model.date ? this.model.date : '-';
		},
		getStatusdescValue() {
			return this.model && this.model.statusdesc ? this.model.statusdesc : '-';
		},
		getFormattedUploaddate() {
			if (this.model && this.model.date) {
				const dateformat = new Date(this.model.date);
				const formattedDate = `${dateformat.toLocaleDateString()} ${dateformat.toLocaleTimeString()}`;
				return formattedDate;
			} else {
				return '-';
			}
		}
	}
};
</script>
